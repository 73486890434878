import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';

import styles from '../styles/index.module.css';

const LABELS = {
  TEXT: (action, id) => `Аккаунт ${id} будет ${action}. Вы уверены?`,
  DISABLE: 'заблокирован',
  ENABLE: 'разблокирован',
  APPROVE: 'Подтвердить',
};

const DIALOG_WIDTH = '400px';

const DisableAccountDialog = ({ onClose, onSave, account, loading }) => {
  const { Enabled, Id } = account;

  const action = Enabled ? LABELS.DISABLE : LABELS.ENABLE;

  return (
    <Dialog
      width={ DIALOG_WIDTH }
      onClick={ onClose }
    >
      <div className={ styles.disable_account_wrapper }>
        <div>
          {LABELS.TEXT(action, Id)}
        </div>
        <div className={ styles.disable_account_buttons }>
          <DecisionButtons
            lableSave={ LABELS.APPROVE }
            onSave={ onSave }
            onCancel={ onClose }
            waitingResponse={ !loading }
          />
        </div>
      </div>
    </Dialog>
  );
};

DisableAccountDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default DisableAccountDialog;
