import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sw-ui';

import { ACCOUNTTEXT, SENDINVOICES } from '../../../../bi/constants/account';
import COMPONENTS from '../../../../bi/constants/components';

import RadioButtonsDialog from '../../../../components/RadioButtonsDialog/index';

import styles from './styles/index.module.css';

const LABELS = {
  HEADER: 'Автоматическая отправка счетов по каждой поездке:',
  WIDTH: '500px',
};

class AutomaticSendInvoices extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  state = {
    showEditSendInvoicesDialog: false,
  };

  toggleEditSendInvoices = () => this.setState({
    showEditSendInvoicesDialog: !this.state.showEditSendInvoicesDialog,
  });

  accountInvoiceName = () => {
    const { account: { DisableAccountInvoice } } = this.props;

    return (
      SENDINVOICES.find(({ value }) => value === DisableAccountInvoice)
        ? SENDINVOICES.find(({ value }) => value === DisableAccountInvoice).label
        : ''
    );
  };

  renderEditSendInvoicesDialog = () => {
    const { account: { DisableAccountInvoice }, onSave } = this.props;
    const { showEditSendInvoicesDialog } = this.state;

    return showEditSendInvoicesDialog ? (
      <RadioButtonsDialog
        buttonValue={ DisableAccountInvoice }
        header={ LABELS.HEADER }
        width={ LABELS.WIDTH }
        onClose={ this.toggleEditSendInvoices }
        onSave={ onSave }
        list={ SENDINVOICES }
      />
    ) : null;
  }

  render() {
    return (
      <div className={ styles.row }>
        <span className={ styles.name }> { LABELS.HEADER } </span>
        <b className={ styles.value }>{ this.accountInvoiceName() }</b>
        <Button
          label={ ACCOUNTTEXT.EDIT }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ this.toggleEditSendInvoices }
        />
        { this.renderEditSendInvoicesDialog() }
      </div>
    );
  }
}

export default AutomaticSendInvoices;
