import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Loading, Select } from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';

import { RATETYPE, BUS_ORDER_RATE } from '../../../../../constants/rateTypes';
import COMPONENTS from '../../../../../bi/constants/components';
import styles from '../styles/dialog.module.css';

const WIDTH = '400px';
const DEFAULT_RATE = 'Fixed180';

class BusRateDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    accountService: PropTypes.object.isRequired,
    currentRate: PropTypes.string.isRequired,
  };

  constructor(props) {
    super();

    const rateType = props.currentRate ? props.currentRate.RateType : DEFAULT_RATE;

    this.state = {
      rates: [],
      rate: { RateType: rateType },
      loading: true,
    };
  }

  componentDidMount() {
    this.props.accountService.getRates(RATETYPE.BUS)
      .then(res => this.setState({ loading: false, rates: res }));
  }

  handleChangeRate = ({ value }) => this.setState({ rate: value });

  handleSave = () => {
    const { accountService } = this.props;
    const { rate } = this.state;
    const body = { AccountId: accountService.get().account.Id, ...rate };

    return accountService.updateRates(RATETYPE.BUS, body);
  };

  renderSelectRate = () => {
    const { rates, rate } = this.state;

    const items = rates.map(item => ({
      value: item,
      label: BUS_ORDER_RATE[item.RateType],
    }));

    const defaultRate = rates.filter(item => item.RateType === rate.RateType);
    const currentRate = defaultRate.length ? defaultRate[0] : rates[0];

    return (
      <div className={ styles.select }>
        <h4>Выписка:</h4>
        <Select
          items={ items }
          value={ currentRate }
          theme={ COMPONENTS.SELECT.THEME.BORDER }
          onChange={ this.handleChangeRate }
        />
      </div>
    );
  };

  renderLoading = () => (
    <Dialog onClick={ this.props.onClose } width={ WIDTH }>
      <div className={ styles.loading }>
        <Loading size={ COMPONENTS.LOADING.SIZE.LARGE } />
      </div>
    </Dialog>
  );

  render() {
    const { onClose } = this.props;
    const { loading } = this.state;

    return loading ? this.renderLoading() : (
      <Dialog onClick={ onClose } width={ WIDTH }>
        <div className={ styles.dialog }>
          <h3>Изменение тарифа для авт/б</h3>
          { this.renderSelectRate() }
          <DecisionButtons
            loadingIncluded
            onCancel={ onClose }
            onSave={ this.handleSave }
          />
        </div>
      </Dialog>
    );
  }
}

export default BusRateDialog;
