import renderComponents from './components';

const NAME = 'sw-unloading-1c';

class Unloading1c {
  constructor(name, context, opts) {
    this.box = opts.box;
    this.name = name;
    this.context = context;

    const appService = this.context.getService('App');
    const companyService = this.context.getService('Company');
    const unloading1cService = this.context.getService('Unloading1c');

    this.page = renderComponents(this.box, {
      appService,
      companyService,
      unloading1cService,
      goBack: this.goBack,
      moveToCompany: this.moveToCompany,
    });
  }

  goBack = () => {
    history.back();
  };

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, Unloading1c);
}
